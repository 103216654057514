/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ButtonLink from 'components/elements/ButtonLink'

// Icons
import Star from 'img/icon/star.inline.svg'

const Rating = styled(BlockDefault)`
  background-color: ${props => props.theme.color.contrast};
  padding: 20px 35px !important;
  border-radius: 25px;
`

const Title = styled.div`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.xm};
  font-weight: ${props => props.theme.font.weight.l};
  font-style: italic;
`

const StyledStar = styled(Star)`
  height: 20px;
  width: 20px;
`

const Content = styled.div`
  background-color: ${props => props.theme.color.background};
`

const Description = styled.div`
  color: ${props => props.theme.color.text.light};

  & h1, & h2, & h3 {
    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xl};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.l};
    }
    
    & strong {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }
`

const Review = ({ fields }) => {
  const stars = []

  for(let i = 0; i < parseFloat(fields.rating); i += 1) {
    stars.push(i)
  }

  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-end mb-n2">
          <Rating className="d-flex mb-n4">
            <Title>
              {fields.title}
            </Title>
            <div className="ml-3">
              {stars.map((value, index) => 
                // eslint-disable-next-line react/no-array-index-key
                <StyledStar key={index} className="ml-1" />)}
            </div>
          </Rating>
        </div>
      </div>
      <Content>
        <div className="container py-5">
          <div className="py-3">
            <Description>
              <ParseContent content={fields.description} />
            </Description>
            {fields.button && (
              <div className="mt-4 text-center">
                <ButtonLink to={fields.button.url}>
                  {fields.button.title}
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </Content>
    </div>
  )
}

export default Review