/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Libraries
import parse from 'html-react-parser'
import Lightbox from 'lightbox-react'
import 'lightbox-react/style.css'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ButtonDefault from 'components/elements/ButtonDefault'

const ImageBlock = styled.div`
  border: 3px solid transparent;
  font-size: 0;
  border-radius: 25px;
  background-color: #e1e1e1;
  width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;

  & > div {
    position: absolute !important;
    top: 0;
    left: 0;
  }

  @media (min-width: 992px) {
    padding-top: ${props => props.height ? props.height : 50}%;
  }

  @media (max-width: 991px) {
    padding-top: 50%;
  }

  &:hover {
    border: 3px solid ${props => props.theme.color.contrast};
  }
`

const StyledVideo = styled.video`
  border-radius: 25px;
  border: 3px solid #e1e1e1;
`

const Gallery = ({ fields }) => {
  const [mediaData, setMediaData] = useState({
    photoIndex: 0,
    isVisible: false,
  })

  let images = []

  if (fields.media[0]) {
    if (fields.media[0].localFile) {
      images = fields.media.map(({ localFile: { childImageSharp: { big } } }) => big.src)
    } else {
      images = fields.media.map(({ src }) => src)
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <div className="row mt-5">
            <div className="col-lg-3" />
            <div className="col-lg-9">
              <Image index={0} media={fields.media} setMediaData={setMediaData} height={150} />
            </div>
          </div>
          <div className="mt-4">
            <Image index={1} media={fields.media} setMediaData={setMediaData} height={60} />
          </div>
        </div>
        <div className="col-lg-8 mt-4 mt-lg-0">
          <div className="row">
            <div className="col-md-6 col-lg-8">
              {/* <Image index={2} media={fields.media} setMediaData={setMediaData} height={65} /> */}
              {/* <iframe
                src="https://player.vimeo.com/video/844200796?h=9f92bcceb5"
                width="100%"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Schmidt Global"
              /> */}
              <StyledVideo width="100%" height="auto" controls poster="https://admin.schmidt-global.nl/wp-content/uploads/2023/07/1697607519-81317f26f6f6e3d6b29baa89bb01790432d01873259ad576ed4e30ba707225e4-d.jpg">
                <source src="https://player.vimeo.com/progressive_redirect/playback/844200796/rendition/1080p/file.mp4?loc=external&signature=96335263736cf783c1f404e2ecaa938690e423ae3773378a4368174582adfa2c" type="video/mp4" />
              </StyledVideo>
            </div>
            <div className="col-md-6 col-lg-5 mt-lg-4 mt-0">
              <Image index={3} media={fields.media} setMediaData={setMediaData} height={150} />
            </div>
            <div className="col-lg-7 mt-4">
              <BlockDefault>
                <div>
                  {parse(fields.title)}
                </div>
                <ParseContent className="mt-3" content={fields.description} />
                <div className="mt-4 text-center">
                  <ButtonDefault small to={fields.button.url} type="next">
                    {fields.button.title}
                  </ButtonDefault>
                </div>
                <div className="mt-3 text-center font-weight-s">
                  {fields.info}
                </div>
              </BlockDefault>
            </div>
          </div>
        </div>
      </div>

      {mediaData.isVisible && (
        <Lightbox
          mainSrc={images[mediaData.photoIndex]}
          nextSrc={images[(mediaData.photoIndex + 1) % images.length]}
          prevSrc={
            images[(mediaData.photoIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() =>
            setMediaData({ ...mediaData, ...{ isVisible: false } })}
          onMovePrevRequest={() =>
            setMediaData({
              ...mediaData,
              ...{
                photoIndex:
                  (mediaData.photoIndex + images.length - 1) % images.length,
              },
            })}
          onMoveNextRequest={() =>
            setMediaData({
              ...mediaData,
              ...{ photoIndex: (mediaData.photoIndex + 1) % images.length },
            })}
        />
      )}
    </>
  )
}

const Image = ({ media, index, setMediaData, height }) => (
  <ImageBlock
    onClick={() =>
      setMediaData({ photoIndex: index, isVisible: true })}
    onKeyPress={() => { }}
    role="button"
    tabIndex="0"
    height={height}
  >
    Open
    {media[index] && (
      <Plaatjie className="h-100 w-100" image={media[index]} />
    )}
  </ImageBlock>
)

export default Gallery