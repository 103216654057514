/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Libraries
import parse from 'html-react-parser'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ButtonAnimateTruck from 'components/elements/ButtonAnimateTruck'
import HeroDefault from 'components/elements/HeroDefault'
import BackgroundDescription from 'components/elements/BackgroundDescription'
import RatingAlt from 'components/Reviews/RatingAlt'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import NationsSeeker from 'components/NationsSeeker'
import Identity from 'components/Identity'
import Review from 'components/Review'
import Gallery from 'components/Gallery'
import Infographic from '../components/Infopgrahic/Infographic'

const ButtonDiv = styled.div`
  margin-top: 2rem !important;
`

const StyledRatingAlt = styled(RatingAlt)`
  margin-top: 2rem;
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout>
    <SEO
      yoast={yoast}
      path={path}
      image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
    />

    <section>
      <HeroDefault image={acf.banner.image} hideOverlay big home rounded={false}>
        <div className="d-flex align-items-center justify-content-start h-100">
          <div className="w-100 mb-n5 mb-md-0">
            <div className="row">
              <div className="col-lg-10 col-xl-11">
                <BlockDefault className="d-flex flex-wrap flex-xl-nowrap mb-3" bigBorderRadius>
                  <div>
                    <div>
                      {parse(acf.banner_block.title)}
                    </div>
                  </div>
                  <div>
                    <div>
                      {parse(acf.banner_block.description)}
                    </div>
                    <ButtonDiv className="mt-5 text-center">
                      <ButtonAnimateTruck to={acf.banner_block.button.url}>
                        {acf.banner_block.button.title}
                      </ButtonAnimateTruck>
                    </ButtonDiv>
                    <StyledRatingAlt noTitle />
                  </div>
                </BlockDefault>
              </div>
            </div>
          </div>
        </div>
      </HeroDefault>
    </section>

    <section>
      <div className="container mt-lg-0 mt-5">
        <BackgroundDescription className="pt-lg-5 mt-lg-3" content={acf.search.background_description} />
      </div>
    </section>

    <section className="mt-lg-5 pt-lg-5">
      <div className="container pt-lg-5 pt-0 mb-5 pt-lg-5">
        <NationsSeeker fields={acf.search} />
      </div>
    </section>

    <section>
      <div className="container pt-lg-0 pt-4 pb-lg-5 mb-lg-3">
        <BackgroundDescription content={acf.identity.background_description} />
      </div>
    </section>

    <section className="pt-lg-5">
      <div className="container mt-lg-5 mb-5 pt-lg-5">
        <Identity fields={acf.identity} />
      </div>
    </section>

    <section>
      <div className="container pb-lg-5 mb-lg-5 mb-md-n4 pt-lg-0 pt-3">
        <BackgroundDescription className="pb-lg-5" content={acf.review.background_description} />
      </div>
    </section>

    <section className="pt-lg-5">
      <Review fields={acf.review} />
    </section>

    <section className="pt-lg-5">
      <div className="container my-lg-5 pt-lg-5 pt-0 pb-5">
        <Gallery fields={acf.gallery} />
      </div>
    </section>

    {acf.show_infographic && (
      <Infographic />
    )}

  </Layout>
)

export default HomeTemplate