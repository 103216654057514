/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import ParseContent from 'components/shared/ParseContent'

// Libraries
import parse from 'html-react-parser'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ButtonLink from 'components/elements/ButtonLink'

// Icons
import Arrow from 'img/icon/arrow-right-circled.inline.svg'

const Front = styled.div`
  z-index: 2;
`

const Back = styled.div`
  z-index: 1;
`

const Identity = ({ fields }) => {
  const [indexOpen, setIndexOpen] = useState(null)

  const setOpen = index => {
    setIndexOpen(index === indexOpen ? null : index)
  }

  return (
    <div className="row">
      <Front className="col-lg-5 d-flex justify-content-end position-relative">
        <div className="mt-lg-n5 mr-lg-n5">
          <div className="mt-lg-n5 mr-lg-n3">
            {fields.usp.map(({ title, description }, index) => (
              <USP 
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={title} 
                description={description} 
                index={index}
                setOpen={setOpen}
                indexOpen={indexOpen}
              /> 
            ))}
          </div>
        </div>
      </Front>
      <Back className="col-lg-7 position-relative mt-4 mt-lg-0">
        <BlockDefault bigBorderRadius>
          {parse(fields.title)}
          <div className="text-justify mt-3">
            <ParseContent content={fields.description} />
          </div>
          <div className="mt-3">
            {fields.button && (
              <ButtonLink to={fields.button.url}>
                {parse(fields.button.title)}
              </ButtonLink>
            )}
          </div>
        </BlockDefault>
      </Back>
    </div>
  )
}

const StyledUSP = styled(BlockDefault)`
  background-color: ${props => props.theme.color.contrast};
  color: ${props => props.theme.color.text.light};
  border-radius: 25px;
  
  & h1, & h2, & h3 {
    font-weight: ${props => props.theme.font.weight.s};
    margin: 0;

    & strong, & b {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }

  @media (min-width: 992px) {
    padding: 25px 35px !important;
    max-width: 400px;
  }

  @media (max-width: 991px) {
    padding: 15px 25px !important;
    width: 100%;
  }
`

const Title = styled(motion.div)`
  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled(Arrow)`
  height: 40px;
  width: 40px;
`

const Description = styled(motion.div)`
  overflow: hidden;
`

export const USP = ({ title, description, index, indexOpen, setOpen }) => {
  const status = indexOpen === index ? 'open' : 'closed'

  return (
    <StyledUSP className="mb-3">
      <Title
        role="button"
        className="d-flex justify-content-between align-items-center"
        onClick={() => {
          setOpen(index)
        }}
      >
        {parse(title)}
        <motion.div
          initial="open"
          variants={{
            open: { rotate: 90 },
            closed: { rotate: 0 },
          }}
          animate={status}
        >
          <Icon />
        </motion.div>
      </Title>
      <Description
        initial="open"
        variants={{
          open: { opacity: 1, height: 'auto' },
          closed: { opacity: 0, height: 0 },
        }}
        animate={status}
      >
        <div className="mt-3">
          <ParseContent content={description} />
        </div>
      </Description>
    </StyledUSP>
  )
}

export default Identity