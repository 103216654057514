/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  display: inline-block;
  
  & > a, & > button {
    font-weight: ${props => props.theme.font.weight.s};
    color: ${props => props.theme.color.text.contrast};
    text-decoration: underline;

    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xm};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.m};
    }
  }
`

const ButtonLink = ({ isAnchor, isCustom, to, children, className }) => {
  return (
    <StyledButton className={className}>
      {isCustom ? children : (
        <ButtonShell to={to} isAnchor={isAnchor}>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonLink